import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from "react-bootstrap"
import { SyncLoader } from "react-spinners"
import toast from "react-hot-toast";

import * as setup from "models/setup";

import FileCard from "components/FileCard"
import FileViewer from "components/FileViewer";
import Lectures from "pages/Lectures";
import LandingPage from "components/LandingPage";

const Home = prop => {
  const [userProfile, setUserProfile] = useState(null)
  const [contents, setContents] = useState([])
  const [listedContent, setListedContent] = useState([])
  const [listedSubjects, setListedSubjects] = useState([])
  const [listedTopics, setListedTopics] = useState([])
  const [listedSubTopics, setListedSubTopics] = useState([])
  const [listedSession, setListedSession] = useState([])
  const [query, setQuery] = useState({})


  const [selectedTopic, setSelectedTopic] = useState("")
  const [selectedSubTopic, setSelectedSubTopic] = useState("")
  const [selectedSession, setSelectedSession] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [downloadReady, setDownloadReady] = useState(true)
  const [signTrigger, setSignTrigger] = useState(true)
  const [selected, setSelected] = useState(null)
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [activeFileTab, setActiveFileTab] = useState({
    name: "All", value: "all"
  })
  const [activeSubjectTab, setActiveSubjectTab] = useState("")
  const [selectedSubjectTab, setSelectedSubjectTab] = useState("")

  const fileTabs = [
    { name: "All", value: "all", icon: "fas fa-file" },
    { name: "Pdf", value: "pdf", icon: "fas fa-file" },
    { name: "PowerPoint", value: "pptx", icon: "fas fa-file" },
    { name: " Audios", value: "audios", icon: "fas fa-volume-up" },
    { name: " Videos", value: "videos", icon: "fas fa-video" },
    { name: "Lectures", value: "lectures", icon: "fas fa-stream" },
    { name: "Practicals ", value: "practicals", icon: "fas fa-video" },
    { name: "Discussions ", value: "discussion", icon: "fas fa-users" },


  ]
  function groupTopicsByUnitAndSession(topics) {
    return topics.reduce((groupedUnits, topic) => {
      // Find or create the unit group
      let unitGroup = groupedUnits.find(group => group.unit === topic.unit);

      if (!unitGroup) {
        unitGroup = {
          unit: topic.unit,
          unitId: topic.unitId,
          sessions: []
        };
        groupedUnits.push(unitGroup);
      }

      // Find or create the session group within the unit
      let sessionGroup = unitGroup.sessions.find(session => session.sessionName === topic.sessionName);

      if (!sessionGroup) {
        sessionGroup = {
          sessionName: topic.sessionName,
          topics: []
        };
        unitGroup.sessions.push(sessionGroup);
      }

      // Add the topic to the session group
      sessionGroup.topics.push(topic);

      return groupedUnits;
    }, []);
  }

  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }

  function findAndStoreUnit(dataArray) {
    if (!Array.isArray(dataArray) || dataArray.length === 0) {
      console.error("Invalid input: The array is empty or not an array.");
      return "";
    }
    const unit = dataArray[0]?.unit;

    if (unit) {
      return unit;
    } else {
      return "";
    }
  }


  const getAllTopics = (params) => {
    setup.getAllTopics(params)
      .then(resp => {
        const data = resp.topics
        setListedSubjects(groupTopicsByUnit(data))
      }).catch(err => {
      })
  }

  const getAllSubTopics = () => {
    setup.getAllSubTopics(`topic=${selectedTopic}`)
      .then(resp => {
        const data = resp?.subTopics
        setListedSubTopics(data)
      }).catch(err => {

      })
  }



  const getAllContents = (params) => {
    setup.getAllContents(params)
      .then(resp => {
        const data = resp?.contents
        setListedContent(resp?.contents)
        setIsLoaded(true)

      }).catch(err => {

      })
  }
  useEffect(() => {
    const quer = `subTopic=${selectedSubTopic}`
    if (selectedSubTopic) {
      getAllContents(quer)
    }
  }, [selectedSubTopic])
  useEffect(() => {
    const quer = `topic=${selectedTopic}`
    if (selectedTopic) {
      getAllContents(quer)
    }
  }, [selectedTopic])

  useEffect(() => {
    if (activeSubjectTab) {
      const m = listedSubjects.find((item => item?.unit === activeSubjectTab))
      setListedTopics(m?.topics)
    }
  }, [activeSubjectTab])

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
    if (profile) {
      setSignTrigger(false)
    } else {
      setSignTrigger(true)
    }
  }, [])
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
    if (profile) {
      setSignTrigger(false)
    } else {
      setSignTrigger(true)
    }
  }, [prop.updateLocalStorage])

  useEffect(() => {
    const quer = `level=${prop.selectedLevel}`
    if (prop.selectedLevel) {
      setListedContent([]),
        setListedSubjects([])
      setListedTopics([])
      setActiveSubjectTab("")
      setSelectedTopic('')
      getAllTopics(quer)
    }
  }, [prop.selectedLevel])

  useEffect(() => {
    const quer = `topic=${selectedSubTopic}${activeFileTab?.value && activeFileTab?.value !== "all"
      ? `&fileType=${activeFileTab.value}`
      : ""
      }`;

    setListedContent([]),
      getAllContents(quer)
  }, [activeFileTab])

  useEffect(() => {
    setListedContent([])
    if (prop.selectedTopics) {
      setSelectedSubjectTab(findAndStoreUnit(prop.selectedTopics))
      setListedTopics(prop.selectedTopics)
    }
  }, [prop.selectedTopics])

  return (
    <div className="page-content bg-white">


      <div className="text-primary">
        {prop.selectedLevel && (<a>{prop.selectedLevel} </a>)}
        {activeSubjectTab && (<a>| {activeSubjectTab} </a>)}
        {selectedSubjectTab && (<a>{selectedSubjectTab} </a>)}
        {selectedTopic && (<a>| {selectedTopic} </a>)}
      </div>



      {prop.selectedLevel ?
        (<Row className="bg-white">
          {!userProfile && (
            <Col md={3} lg={2} className="d-md-none">

              {listedSubjects.length > 0 && (<div className="d-flex flex-row flex-wrap bg-light p-2  text-black">
                {/* <div className="d-flex justify-content-centerd align-items-center me-1 ">
                Learning Areas:
              </div> */}
                Learning Areas:
                {/* <div className="d-flex flex-row"> */}
                {listedSubjects.map(((item, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center justify-content-center border-3 btn border-0  btn-sm me-md-3 ms-1
                  
                  bg-${activeSubjectTab === item?.unit ? "secondary" : ""}
text-${activeSubjectTab === item.unit ? "white" : "secondary"}
`}
                    href={`#${item.name} `}
                    onClick={() => { setListedSubTopics([]), setActiveSubjectTab(item?.unit) }}
                  >
                    <i className={`${item?.icon} `}></i>
                    <h6 className={`mx-1 my-0 font-size-12`} >
                      {item.unit}
                    </h6>
                  </div>
                )))}
              </div>
              )}
            </Col>
          )}
          {!userProfile && (<Col md={3} lg={2} className="d-none d-md-block">
            {listedSubjects.length > 0 && (<div className="d-flex flex-column bg-light p-2">
              <div className="d-flex justify-content-center align-items-center me-1 text-black">
                Learning Areas </div> {listedSubjects.map(((item, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center justify-content-center border-3 btn border-0  btn-sm me-md-3

bg-${activeSubjectTab === item?.unit ? "secondary" : ""}
text-${activeSubjectTab === item.unit ? "white" : "secondary"}
`}
                    href={`#${item.name} `}
                    onClick={() => { setListedSubTopics([]), setActiveSubjectTab(item?.unit) }}
                  >
                    <i className={`${item?.icon} `}></i>
                    <h6 className={`mx-1 my-0 `} >
                      {item.unit}
                    </h6>
                  </div>


                )))}
            </div>
            )}
          </Col>)}


          <Col className="d-flex pb-5 scrollable-div " style={{
            height: "70vh",
            overflowY: "auto",
          }}>
            {isLoading ?
              <div className="d-flex p-5 my-5 justify-content-center">
                <SyncLoader
                  color='#082F1A'
                  size={20}
                  margin={10}
                />

              </div> :
              <div className=" w-100"
              >
                <Row className="mt-1">
                  <Col md={12} className="d-flex justify-content-between justify-content-md-start">
                    {listedSession.map((item, index) => (
                      <Col
                        xs={4}
                        key={index}
                        className={`d-flex align-items-center col col-md-2 justify-content-center border-3 btn border-0 btn-sm me-md-3

bg-${selectedSession === item?.sessionName ? "warning" : "white"}
text-${selectedSession === item?.sessionName ? "white" : "warning"}
`}
                        href={`#${item} `}
                        onClick={() => setSelectedSession(item?.sessionName)}
                      >
                        <h6 className={`mx-1 my-0 `} >
                          {item?.sessionName}
                        </h6>
                      </Col>

                    ))}
                  </Col>
                  <Col md={3} lg={2} className="d-flex d-md-none   ">
                    {listedTopics.length > 0 && (<div className="d-flex flex-column flex-wrap p-2 bg-light w-100">
                      <div className="d-flex align-items-center text-black">
                        Strands
                      </div>

                      {listedTopics.map((item, index) => (
                        <a
                          key={index}
                          className={`d-flex align-items-enter justify-content-start border-0 border-1 mx-0 px-0
bg-${selectedTopic === item?.topicName ? "" : ""}
text-${selectedTopic === item?.topicName ? "primary" : "warning"}
`}
                          href={`#${item?.topicName} `}
                          onClick={() => { setListedSubTopics([]), setSelectedSubTopic(''), setListedContent([]), setSelectedTopic(item?.topicName) }}
                        >
                          <h6 className={`mx-1 my-0 font-size10  d-flex d-md-none`} >
                            {item?.topicName}
                          </h6>
                          <h6 className={`mx-1 my-0  d-none d-md-flex`} >
                            {item?.topicName}
                          </h6>
                        </a>
                      ))}
                    </div>
                    )}
                  </Col>
                  <Col md={3} lg={2} className="d-none d-md-block">
                    {listedTopics.length > 0 && (<Col className="d-flex flex-column flex-wrap">
                      <div className="d-flex align-items-center text-black">
                        Strands
                      </div>

                      {listedTopics.map((item, index) => (
                        <a
                          key={index}
                          className={`d-flex align-items-center justify-content-start border-0 border-1 mx-0 px-0
              bg-${selectedTopic === item?.topicName ? "white" : "white"}
          text-${selectedTopic === item?.topicName ? "primary" : "warning"}
          `}
                          href={`#${item?.topicName} `}
                          onClick={() => { setListedSubTopics([]), setSelectedSubTopic(''), setListedContent([]), setSelectedTopic(item?.topicName) }}
                        >
                          <h6 className={`mx-1 my-0 font-size-10  d-flex d-md-none`} >
                            {item?.topicName}
                          </h6>
                          <h6 className={`mx-1 my-0  d-none d-md-flex`} >
                            {item?.topicName}
                          </h6>
                        </a>
                      ))}
                    </Col>
                    )}
                  </Col>
                  <Col>
                    {listedContent.length === 0 ? <div className="d-flex text-primary justify-content-center   my-5">
                      {/* <h5>The are no files for this section</h5> */}
                    </div> :
                      <div className=" d-flex flex-row">
                        <div className={`d-${showFileViewer ? "none" : "flex"} d-md-flex  flex-wrap col col-md-${showFileViewer ? 4 : 12} col - lg - ${showFileViewer ? 2 : 12} pb - 5 b`}>
                          {listedContent.map((item, index) => (
                            <FileCard
                              key={index}
                              item={item}
                              downloadReady={downloadReady}
                              signTrigger={signTrigger
                              }
                              setSignTrigger={setSignTrigger}
                              setShowSignUpModal={prop.setShowSignUpModal}
                              setFileViewer={setShowFileViewer}
                              fileViewer={showFileViewer}
                              setSelected={setSelected}
                            />
                          ))}
                        </div >

                        <div className=" w-100">
                          {showFileViewer && (

                            <FileViewer
                              selected={selected}
                              setSelelected={setSelected}
                              setFileViewer={setShowFileViewer}
                              fileViewer={showFileViewer}

                            />

                          )}

                        </div>

                      </div>}
                  </Col>
                </Row>


              </div>}



          </Col>


        </Row>) : (
          <LandingPage levelTypes={prop.levelTypes}
            setSelectedLevel={prop.setSelectedLevel}
            setShowSignUpModal={prop.setShowSignUpModal} />
        )}




      <Row
        className="bg-light mb-1">
        <Col
          md={8}
          lg={6}
          className="d-flex scrollable-div justify-content-between p-1 "
          style={{ overflowX: "auto", }}>
          {fileTabs.map(((item, index) => (
            <div
              key={index}
              className={`d-flex align-items-center justify-content-center border-3 btn border-0 border-bottom btn-sm me-2 me-md-3 font-size-10 ps-1 pe-1

bg-${activeFileTab?.name === item?.name ? "primary" : "white"}
text-${activeFileTab?.name === item.name ? "white" : "primary"}
`}
              href={`#${item.name} `}
              onClick={() => setActiveFileTab({ name: item.name, value: item?.value })}
            >
              <i className={`${item?.icon} py-0 my-0 me-1 font-size-`}></i>
              {/* <p className={`mx - 1 my - 0 py - 0 `} > */}
              {item.name}
              {/* </p> */}
            </div>


          )))}
        </Col>


      </Row>
    </div>
  )
}

Home

export default Home