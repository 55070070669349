
// main API URL
let baseUrl = "https://tehuti-apis.onrender.com";
// "https://tehuti-apis.onrender.com";
//"https://api.iq.steticduka.co.ke";
// "http://localhost:3001"
//"http://localhost:3009"
//"https://api.steticduka.co.ke";

export const getApi = {
  ACCOUNTS: {
    VERIFY_OTP: `${baseUrl}/users/verifyOtp`,
    SEND_OTP: `${baseUrl}/users/sendOtp`,
    REGISTER: `${baseUrl}/users/register`,
    REGISTER_AS_ADMIN: `${baseUrl}/users/registerByAdmin`,
    GET_ALL_USERS: `${baseUrl}/users/getallusers`,
    GET_USER_BY_ID: `${baseUrl}/users/getUserById`,
    UPDATE_USER: `${baseUrl}/users/updateUserDetails`,
    UPDATE_PASSWORD: () => `${baseUrl}/users/updatePassword/${id}`,
    ACTIVATE_USER: `${baseUrl}/users/activate`,
    DEACTIVATE_USER: `${baseUrl}/users/de-activate`,
    DELETE_USER: (id) => `${baseUrl}/users/deleteUser/${id}`,
    FORGET_PASSWORD: `${baseUrl}/users/forgetPassword`,
    SET_PASSWORD: `${baseUrl}/users/setNewPassword`,

  },

  LOGS: {
    // items
    GET_ALL_LOGS: `${baseUrl}/logs/getAllLogs`,
    GET_LOG_BY_ID: `${baseUrl}/logs/getLogById`,



  },
  FILES: {
    //GET
    GET_ALL_FILES: `${baseUrl}/files/getAllFiles`,
    GET_FILE_BY_ID: `${baseUrl}/files/getFileById`,

    //POST
    ADD_FILE: `${baseUrl}/files/addFile`,
    UPLOAD_FILE: `${baseUrl}/files/uploadFile`,
    //PUT
    UPDATE_FILE: `${baseUrl}/files/updateFile`,
    //DELETE
    DELETE_FILE: `${baseUrl}/files/deleteFile`,
  },

  SETUP: {
    //levelTypes
    GET_ALL_LEVELTYPES: `${baseUrl}/leveltypes/getAllLevelTypes`,
    GET__LEVELTYPES_BY_ID: `${baseUrl}/leveltypes/getLevelTypesById`,
    GET_LEVELTYPES_BY_NAME: `${baseUrl}/leveltypes/getLevelTypeById`,
    //POST
    ADD_LEVELTYPE: `${baseUrl}/leveltypes/addLevelType`,
    //PUT
    UPDATE_LEVELTYPE: `${baseUrl}/leveltypes/updateLevelType`,
    //DELETE
    DELETE_LEVELTYPE: `${baseUrl}/leveltypes/deleteLevelType`,

    //level
    GET_ALL_LEVELS: `${baseUrl}/levels/getAllLevels`,
    GET__LEVEL_BY_ID: `${baseUrl}/levels/getLevelById`,
    GET_LEVEL_BY_NAME: `${baseUrl}/levels/getLevelId`,
    //POST
    ADD_LEVEL: `${baseUrl}/levels/addLevel`,
    //PUT
    UPDATE_LEVEL: `${baseUrl}/levels/updateLevel`,
    //DELETE
    DELETE_LEVEL: `${baseUrl}/levels/deleteLevel`,

    //units
    GET_ALL_UNITS: `${baseUrl}/units/getAllUnits`,
    GET__UNIT_BY_ID: `${baseUrl}/units/getUnitById`,
    //POST
    ADD_UNIT: `${baseUrl}/units/addUnit`,
    ADD_TOPIC: `${baseUrl}/units/addTopic`,
    //PUT
    UPDATE_UNIT: `${baseUrl}/units/updateUnit`,
    //DELETE
    DELETE_UNIT: `${baseUrl}/units/deleteUnit`,


    //topics
    GET_ALL_TOPICS: `${baseUrl}/topics/getAllTopics`,
    GET__TOPIC_BY_ID: `${baseUrl}/units/getTopicById`,
    GET_ALL_CONTENTS: `${baseUrl}/contents/getAllContents`,
    //POST
    ADD_TOPIC: `${baseUrl}/topics/addTopic`,
    UPLOAD_CONTENT: `${baseUrl}/contents/addContent`,
    //PUT
    UPDATE_TOPIC: `${baseUrl}/topics/updateTopic`,
    //DELETE
    DELETE_TOPIC: `${baseUrl}/topics/deleteTopic`,

    //subTopics
    GET_ALL_SUB_TOPICS: `${baseUrl}/subTopics/getAllSubTopics`,
    GET__SUB_TOPIC_BY_ID: `${baseUrl}/subTopics/getSubTopicById`,
    //POST
    ADD_SUB_TOPIC: `${baseUrl}/subTopics/addSubTopic`,
    //PUT
    UPDATE_SUB_TOPIC: `${baseUrl}/subTopics/updateSubTopic`,
    //DELETE
    DELETE_SUB_TOPIC: `${baseUrl}/subTopics/deleteSubTopic`,

    //category
    GET_ALL_CATEGORIES: `${baseUrl}/categories/getAllCategories`,
    GET_CATEGORY_BY_ID: `${baseUrl}/categories/getCategoryById`,
    GET_CATEGORY_BY_NAME: `${baseUrl}/categories/getCategoryByName`,
    //POST
    ADD_CATEGORY: `${baseUrl}/categories/addCategory`,
    //PUT
    UPDATE_CATEGORY: `${baseUrl}/categories/updateCategory`,
    //DELETE
    DELETE_CATEGORY: `${baseUrl}/categories/deleteCategory`,

    //subCategory
    GET_ALL_SUB_CATEGORIES: `${baseUrl}/subCategories/getAllSubCategories`,
    GET_SUB_CATEGORY_BY_ID: `${baseUrl}/subCategories/getSubCategoryById`,
    GET_SUB_CATEGORY_BY_NAME: `${baseUrl}/subCategories/getSubCategoryByName`,
    //POST
    ADD_SUB_CATEGORY: `${baseUrl}/subCategories/addSubCategory`,
    //PUT
    UPDATE_SUB_CATEGORY: `${baseUrl}/subCategories/updateSubCategory`,
    //DELETE
    DELETE_SUB_CATEGORY: `${baseUrl}/subCategories/deleteSubCategory`,

    //quizes
    GET_ALL_QUIZES: `${baseUrl}/quizes/getAllQuizes`,
    GET_QUIZ_BY_ID: `${baseUrl}/quizes/getQuizById`,
    //POST
    ADD_QUIZ: `${baseUrl}/quizes/addQuiz`,
    //PUT
    UPDATE_QUIZ: `${baseUrl}/quizes/updateQuiz`,
    //DELETE
    DELETE_QUIZ: `${baseUrl}/quizes/deleteQuiz`,

    //Tests
    GET_ALL_TESTS: `${baseUrl}/tests/getAllTests`,
    GET_TEST_BY_ID: `${baseUrl}/tests/getTestById`,
    //POST
    ADD_TEST: `${baseUrl}/test/addTests`,
    //PUT
    UPDATE_TEST: `${baseUrl}/tests/updateTests`,
    //DELETE
    DELETE_TEST: `${baseUrl}/tests/deleteTests`,

  },

  LECTURES: {
    //Tests
    GET_ALL_LECTURES: `${baseUrl}/lectures/getAllLectures`,
    GET_LECTURE_BY_ID: `${baseUrl}/lectures/getLecturetById`,
    //POST
    ADD_LECTURE: `${baseUrl}/lectures/addLecture`,
    JOIN_LECTURE: `${baseUrl}/lectures/joinLecture`,
    //PUT
    UPDATE_LECTURE: `${baseUrl}/lectures/updateLecture`,
    //DELETE
    DELETE_LECTURE: `${baseUrl}/lectures/deleteLecture`,
  },
  ANALYTICS: {
    //category
    GET_ANALYTICS: `${baseUrl}/analytics/getAnalytics`,


  }

};
