import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Overlay, Popover, OverlayTrigger, Row, Col } from "react-bootstrap"

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';


import audioImage from "assets/images/audio.png";
import pdfImage from "assets/images/pdfLogo.png";
import toast from "react-hot-toast";
import moment from "moment"
import { propTypes } from "react-bootstrap/esm/Image";


const FileViewer = props => {
  const [viewPdf, setViewPdf] = useState(false)
  const [show, setShow] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const zoomPluginInstance = zoomPlugin();
  const { Zoom, ZoomInButton, ZoomOutButton, zoomTo } = zoomPluginInstance;

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const item = props.selected

  const printPDF = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = function () {
      printWindow.print();
    };
  };

  return (
    <div className="col bg-warning ">

      <div className="  justify-content-between align-item-center bg-light px-2  ">
        {item.fileType === "pdf" ?
          <a className="" >

            <Card
            >
              <Card.Header className="d-flex bg-white justify-content-evenly" closeButton>
                <div className="d-flex flex-column w-100 justify-content-between">


                  <div className="me-2  d-flex  justify-content-end">
                    <div className="d-none d-md-flex justify-content-center mt-2" style={{ backgroundColor: '' }}>
                      <ZoomInButton />
                      <Zoom />
                      <ZoomOutButton />
                    </div>
                    <button className="btn" onClick={() => printPDF(item?.filePath)}>

                      <i className="   fas fa-print me-2" aria-hidden="true"></i> </button>

                    <a className="btn " href={item?.filePath} download={item?.title}>
                      <i className="fas fa-download" aria-hidden="true"></i>
                      {/* <p className="ms-2 "> Download</p> */}
                    </a>
                    <div className="btn fs-bold"
                      onClick={() => {
                        props.setFileViewer(false)
                      }} >
                      <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                    </div>
                  </div>
                  <Card.Title className="text-centered"> {item?.title}</Card.Title>

                </div>


              </Card.Header>
              <div className="overflow-y-auto pb-5 mx-1 vh-100">
                <Viewer
                  fileUrl={item?.filePath}
                  theme={{
                    theme: "Light"
                  }}

                  plugins={[zoomPluginInstance]}
                />
                {/* <PDFViewer pdfUrl={item?.filePath} /> */}
              </div>
            </Card>



          </a>
          : item.fileType === "audio" ? <Card>
            <Card.Header className="bg-white p-0">
              <div className="d-flex flex-column w-100 justify-content-between">
                <div className="me-2  d-flex  justify-content-end">
                  <div className="btn fs-bold"
                    onClick={() => {
                      props.setFileViewer(false)
                    }} >
                    <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="d-flex align-items-center justify-content-center py-5 mb-2">
              <audio
                className="item-image bg-light"
                controls
                src={item?.filePath}
                type="audio/ogg"
              />
            </Card.Body>
          </Card> : item.fileType === "video" ?
            <Card>
              <Card.Header className="bg-white p-0">
                <div className="d-flex flex-column w-100 justify-content-between">
                  <div className="me-2  d-flex  justify-content-end">
                    <div className="btn fs-bold"
                      onClick={() => {
                        props.setFileViewer(false)
                      }} >
                      <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <video
                className="item-image px-2"
                controls
                src={item?.filePath}
                type="video/mp4"


              />
              <div className="px-2 py-2">

                <ul className='list-inline mb-0 font-size-12 d-flex flex-column '>
                  <li className="list-inline-item text-black   ">

                    <b>  {item?.title}</b>
                  </li>
                  <li className="list-inline-item text-black  font-size-10 ">


                    {moment(item?.created).endOf('day').fromNow()}
                  </li>
                </ul>

              </div>
            </Card>
            : null}

      </div>

    </div>


  )
}
FileViewer.propTypes = {
  selected: propTypes.obj,
  setSelelected: propTypes.func,
  signTrigger: propTypes.bool,
  downloadReady: propTypes.bool,
  setSignTrigger: propTypes.func
}
export default FileViewer