import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>


            <Col
              lg={{ span: '4', order: 3 }}
              md={6}
              xs='12'
              className="d-flex justify-content-center justify-content-lg-end justify-content-md-center "
            >
              {/* Social Media Icons */}
              <ul className="list-unstyled d-flex ">
                <li className="ms-2">
                  <a href="https://www.facebook.com/TehutiOfficialPage/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-facebook fs-4 p-1 rounded-circle text-white ' style={{ backgroundColor: "#0866FF" }} />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://twitter.com/Tehuti" target="_blank" rel="noreferrer">
                    <i className='bx bx-x fs-4 p-1 rounded-circle bg-black text-white' />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://tiktok.com/Tehuti" target="_blank" rel="noreferrer" className="rounded roudend-circle d-flex justify-content-center align-items-center  p-1 fs-4 text-black">
                    <FontAwesomeIcon icon={faTiktok} />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.linkedin.com/company/tehuti/?originalSubdomain=ke" target="_blank" rel="noreferrer">
                    <i className='bx bxl-linkedin fs-4 p-1 rounded-circle text-white'
                      style={{ backgroundColor: "#0077B5" }}
                    />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.instagram.com/tehuti/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-instagram fs-4 p-1 rounded-circle text-white'
                      style={{ backgroundColor: "#F56040" }} />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.youtube.com/channel/UCGsUrqRsbIc-7Y55D--SBgwcfg/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-youtube fs-4 p-1 rounded-circle bg-white text-danger' />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://api.whatsapp.com/send/?phone=254708315284&text=0&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                    <i className='bx bxl-whatsapp fs-4 p-1 rounded-circle bg-success text-white' />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
